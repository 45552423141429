import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                nav: 'toggleNav',
                cart: 'toggleCart',
                category: 'toggleCategory',
            },
        }
    }

    init() {

    }

    toggleNav(e) {
        const burger = this.$('nav')[0];
        const text = burger.querySelector('.-hover');
        const html = document.querySelector('html');
        if(html.classList.contains('has-navOpen')) {
            html.classList.remove('has-navOpen');
            burger.classList.remove('is-active');
            text.textContent = text.dataset.texthover;
        } else {
            html.classList.add('has-navOpen');
            burger.classList.add('is-active');
            text.textContent = text.dataset.textclose;
        }
    }

    toggleCategory(e){
        if (e.currentTarget.closest('.c-header')){
            if(e.currentTarget.closest('.c-header').getElementsByClassName('is-open').length > 0 && e.currentTarget.classList.contains('is-open') == false){
                e.currentTarget.closest('.c-header').getElementsByClassName('is-open')[0].classList.remove('is-open');
            } 
        } else{
            if (e.currentTarget.closest('.c-nav').getElementsByClassName('is-open').length > 0 && e.currentTarget.classList.contains('is-open') == false) {
                e.currentTarget.closest('.c-nav').getElementsByClassName('is-open')[0].classList.remove('is-open');
            }
        }
        
        e.currentTarget.classList.toggle('is-open');
    }
}